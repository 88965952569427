import Loaders from 'components/Loaders';
import React, { Suspense } from 'react';

const Routes = React.lazy(() => import('routes'));
const App = () => {
  return (
    <>
      <Suspense fallback={<Loaders type="SiteLoader" />}>
        <Routes />
      </Suspense>
    </>
  );
};

export default App;
