export const languageConstant: { [key: string]: string } = {
  ES: 'es',
  EN: 'en',
  DEFAULT: 'en',
};

export enum LanguagesEnum {
  SPANISH = 'spanish',
  ENGLISH = 'english',
}

export const ToastVariant = {
  SUCCESS: 'Success',
  ERROR: 'Error',
  WARNING: 'Warning',
};
// export const FeatureEnum = {
//   Dashboard: 'Dashboard',
//   Users: 'Users',
//   Home: 'Home',
//   Cms: 'Cms',
//   Course: 'Course',
//   Dictionary: 'Dictionary',
//   Profile: 'Profile',
// };
export enum FeatureEnum {
  Permission = 'Permission',
  RolePermission = 'RolePermission',
  Feature = 'Feature',
  Role = 'Role',
  User = 'User',
  Dashboard = 'Dashboard',
  Profile = 'Profile',
  Course = 'Course',
  Organization = 'Organization',
  Teacher = 'Teacher',
  Student = 'Student',
  Calendar = 'Calendar',
  Subscription = 'Subscription',
  Dictionary = 'Dictionary',
  Giftcard = 'GiftCard',
  Community = 'Community',
  AuditLog = 'AuditLog',
  Quiz = 'Quiz',
  Feedback = 'Feedback',
  Vocabularies = 'Vocabularies',
  SupportRequest = 'SupportRequest',
  Comments = 'Comments',
  Posts = 'Posts',
  Likes = 'Likes',
  Cms = 'Cms',
  Blog = 'Blog',
  Tags = 'Tags',
  LiveAssessment = 'LiveAssessment',
  Discount = 'Discount',
  CommonSettings = 'CommonSettings',
  CmsManagement = 'CmsManagement',
  Event = 'Event',
  ReportedComments = 'ReportedComments',
  Metrics = 'Metrics',
  Report = 'Report',
}

export const PermissionEnum = {
  Update: 'Update',
  Delete: 'Delete',
  Create: 'Create',
  View: 'View',
};

export const Roles = Object.freeze({
  Admin: 'Admin',
  Organization: 'Organization',
  Teacher: 'Teacher',
  Student: 'Student',
});

export enum ProvidersEnum {
  EMAIL = 'email',
  GOOGLE = 'google',
  FACEBOOK = 'facebook',
  APPLE = 'apple',
  MICROSOFT = 'azure',
}

export const LayoutConstant = {
  CMS: 'CMS',
  ...Roles,
};

export const ApiCallConstant: { [key: string]: string } = {
  es: 'spanish',
  en: 'english',
};
export enum OrganizationTypeEnum {
  SCHOOL_FOR_DEAF = 'School for Deaf',
  PUBLIC_PRIVATE_SCHOOL = 'Public/Private school',
  CORPORATION = 'Corporation',
  NON_PROFIT_ORG = 'Non Profit Org',
  HOME_SCHOOL = 'Home school',
  OTHER = 'Other',
}

export const OrganizationTypeOption = [
  {
    label: 'School for Deaf',
    value: 'School for Deaf',
  },
  {
    label: 'Public/Private school',
    value: 'Public/Private school',
  },
  {
    label: 'Corporation',
    value: 'Corporation',
  },
  {
    label: 'Non Profit Org',
    value: 'Non Profit Org',
  },
  {
    label: 'Home school',
    value: 'Home school',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

export const KeyLearningOptions = [
  { value: 'numbers', label: 'Numbers' },
  { value: 'basic fingerspell', label: 'Basic FingerSpell' },
  { value: 'interesting topic', label: 'Interesting Topic' },
];

export enum CourseTypeEnum {
  SELF_PACED_COURSES = 'Self Paced Courses',
  IN_PERSON_CLASS = 'In-Person Class',
  ZOOM_CLASS = 'Zoom Class',
  APPOINTMENTS = 'Appointments',
  MINI_LESSONS = 'Mini Course',
  FULL_COURSE = 'Full Course',
}

export enum CourseSubTypeEnum {
  MINI_COURSE = 'Mini Course',
}

export enum CourseMaterialTypeEnum {
  PRACTICE = 'practice',
  TEACHING = 'teaching',
}
export const TABLE_DATA_LIMIT = 10;

export enum StatusTypeEnum {
  REQUEST = 'Request',
  RESPONDED = 'Responded',
  CLOSED = 'Closed',
}

export enum ReplyTypeEnum {
  USER = 'User',
  ADMIN = 'Admin',
}
export enum CourseProgressEnum {
  Pending = 'pending',
  Completed = 'completed',
  In_Progress = 'in-progress',
  Not_Started_En = 'Course Not Started',
  Not_Started_Es = 'Cursos no iniciados',
}

export const DurationLogs = [
  { value: 'all', label: 'All' },
  { value: 'today', label: 'Today' },
  { value: 'yesterday', label: 'Yesterday' },
  { value: 'last_seven_days', label: 'Last 7 Days' },
  { value: 'last_thirty_days', label: 'Last 30 Days' },
  { value: 'this_week', label: 'This Week' },
  { value: 'last_week', label: 'Last Week' },
  { value: 'this_month', label: 'This Month' },
  { value: 'last_month', label: 'Last Month' },
  { value: 'last_three_months', label: 'Last 3 Months' },
];

export const EventLogs = [
  { value: 'all', label: 'All' },
  { value: 'REGISTER', label: 'User Register' },
  { value: 'LOGIN', label: 'User Login Success' },
  { value: 'LOGOUT', label: 'User Logout Success' },
  { value: 'BLOG_CREATED', label: 'Blog Created' },
  { value: 'BLOG_UPDATE', label: 'Blog Updated' },
  { value: 'BLOG_DELETE', label: 'Blog Deleted' },
  { value: 'CMS_PAGE_UPDATE', label: 'CMS Page Section Created' },
  { value: 'CMS_PAGE_CREATE', label: 'CMS Page Section Updated' },
  { value: 'COMMENT_CREATE', label: 'Comment Created' },
  { value: 'COMMENT_UPDATE', label: 'Comment updated' },
  { value: 'COMMENT_DELETE', label: 'Comment Deleted' },
  { value: 'COMMUNITY_CREATE', label: 'Community Created' },
  { value: 'COMMUNITY_UPDATE', label: 'Community Updated' },
  { value: 'COMMUNITY_DELETE', label: 'Community Deleted' },
  { value: 'COMMUNITY_MEMBER_CREATE', label: 'Community Member Created' },
  { value: 'COMMUNITY_MEMBER_UPDATE', label: 'Community Member Updated' },
  { value: 'COMMUNITY_MEMBER_DELETE', label: 'Community Member Deleted' },
  { value: 'QUIZ_CREATE', label: 'Quiz Created' },
  { value: 'QUIZ_UPDATE', label: 'Quiz Updated' },
  { value: 'COURSE_CREATE', label: 'Course Created' },
  { value: 'COURSE_UPDATE', label: 'Course Updated' },
  { value: 'COURSE_DUPLICATE', label: 'Course Duplicated' },
  { value: 'COURSE_REPUBLISHED', label: 'Course Re-Published' },
  { value: 'COURSE_PUBLISHED', label: 'Course Published' },
  { value: 'MODULE_CREATE', label: 'Module Created' },
  { value: 'MODULE_UPDATE', label: 'Module Updated' },
  { value: 'MODULE_DUPLICATE', label: 'Module Duplicated' },
  { value: 'WEEK_CREATE', label: 'Week Created' },
  { value: 'LESSON_CREATE', label: 'Lesson Created' },
  { value: 'LESSON_UPDATE', label: 'Lesson Updated' },
  { value: 'LESSON_DUPLICATE', label: 'Lesson Duplicated' },
  { value: 'EVENT_CREATE', label: 'Event Created' },
  { value: 'EVENT_UPDATE', label: 'Event Updated' },
  { value: 'EVENT_DELETE', label: 'Event Deleted' },
  { value: 'FEEDBACK_CREATE', label: 'FeedBack Created' },
  { value: 'TAG_CREATE', label: 'Tag Created' },
  { value: 'LIKE_CREATE', label: 'Like Created' },
  { value: 'ORGANIZATION_TYPE_CREATE', label: 'Organization Type Created' },
  { value: 'ORGANIZATION_TYPE_UPDATE', label: 'Organization Type Updated' },
  { value: 'ORGANIZATION_TYPE_DELETE', label: 'Organization Type Deleted' },
  { value: 'POST_CREATE', label: 'Post Created' },
  { value: 'POST_UPDATE', label: 'Post Updated' },
  { value: 'POST_DELETE', label: 'Post Deleted' },
  { value: 'REPORT_COMMENT_CREATE', label: 'Report Comment Created' },
  { value: 'SUBSCRIPTION_CREATE', label: 'Subscription Created' },
  { value: 'SUBSCRIPTION_DELETE', label: 'Subscription Deleted' },
  { value: 'SUBSCRIPTION_UPDATE', label: 'Subscription Updated' },
  { value: 'SUBSCRIPTION_STATUS_UPDATE', label: 'Subscription Status Updated' },
  { value: 'SUPPORT_REQUEST_CREATE', label: 'Support Request Created' },
  { value: 'SUPPORT_REQUEST_REPLY_CREATE', label: 'Support Request Reply Created' },
  { value: 'SUPPORT_REQUEST_QUERY_CREATE', label: 'Support Request Query Created' },
  { value: 'SUPPORT_REQUEST_QUERY_UPDATE', label: 'Support Request Query Updated' },
  { value: 'TAG_UPDATE', label: 'Tag Updated' },
  { value: 'TAG_DELETE', label: 'Tag Deleted' },
  { value: 'USER_CREATE', label: 'User Created' },
  { value: 'USER_INFO_UPDATE', label: 'User Info Updated' },
  { value: 'USER_DELETE', label: 'User Deleted' },
  { value: 'USER_SIGN_VIEWED', label: 'User viewed a Sign' },
  { value: 'VOCABULARY_SYNC_START', label: 'Vocabulary syncing started' },
  { value: 'VOCABULARY_SYNC_COMPLETE', label: 'Vocabulary syncing completed' },
  { value: 'VOCABULARY_SYNC_FAIL', label: 'Vocabulary syncing failed' },
  { value: 'USER_LEARN_VOCAB_UPDATE', label: 'User learned vocab updated' },
  { value: 'VOCAB_STATUS_UPDATE', label: 'Vocabulary status updated' },
];

export const statusFilter = [
  { value: 'all', label: 'All' },
  {
    value: 'active',
    label: 'Active',
  },
  {
    value: 'inactive',
    label: 'Inactive',
  },
];
export enum StatusEnum {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export const Language = [
  {
    label: LanguagesEnum.ENGLISH,
    value: LanguagesEnum.ENGLISH,
  },
  {
    label: LanguagesEnum.SPANISH,
    value: LanguagesEnum.SPANISH,
  },
];
