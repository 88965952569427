import { useEffect, useRef, useState } from 'react';

// ** Components **

// ** type **
import NameBadge from 'components/Badge/NameBadge';
import Icon from 'components/Icon';
import { REACT_APP_BACKEND_URL } from 'config';
import { getPresignedImageUrl } from 'services/aws.service';
import { IImageProps } from './interface';

const Image = (props: IImageProps) => {
  const {
    src = '',
    alt,
    imgClassName = '',
    NameBadgeParentClass,
    serverPath = true,
    firstName,
    lastName,
    disableLoader = false,
    iconClassName,
    iconName = 'noImgStrokeSD',
    // loaderType = '',
    height,
    width,
    // loaderClassName,
    showImageLoader = true,
    isFromDataBase = true,
  } = props;

  // ** States **
  const [fetchError, setFetchError] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const [isMounted, setIsMounted] = useState(false);
  const [imageURL, setImageURL] = useState<string | File>('');
  const isLoading = useRef<boolean>(true);

  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true);
    }
  }, []);

  useEffect(() => {
    if (
      src &&
      src !== '' &&
      typeof src === 'string' &&
      serverPath &&
      (src.includes('s3.amazonaws.com') || !src.includes('https://')) &&
      iconName === 'noImgStrokeSD'
    ) {
      isLoading.current = true;
      loadServerImage(src);
    } else {
      setImageURL(src || '');
    }
  }, [src, height, width, serverPath]);

  const loadServerImage = async (path: string) => {
    const result = await getPresignedImageUrl(path, height, width, true);
    setImageURL(result || '');
  };

  const imgComponent = () => {
    if (imageURL) {
      if (fetchError) {
        if (firstName) return;
        isLoading.current = false;
        return (
          <img
            className={`block ${imgClassName}`}
            src="/images/no-image.png"
            alt={`${alt ?? src}`}
          />
        );
      }

      return (
        <img
          className={`${!isImageLoaded && isLoading.current ? 'hidden' : 'block'} ${imgClassName}`}
          src={
            isFromDataBase
              ? imageURL.toString().includes('https://')
                ? (imageURL as string)
                : `${(REACT_APP_BACKEND_URL as string) + imageURL}`
              : (imageURL as string)
          }
          alt={`${alt || src}`}
          onLoad={() => {
            setIsImageLoaded(true);
            isLoading.current = false;
          }}
          onError={() => {
            setFetchError(true);
            setIsImageLoaded(true);
          }}
          height={height}
          width={width}
          referrerPolicy="no-referrer"
        />
      );
    }
    return <Icon className={iconClassName} name={iconName} />;
  };

  return (
    <>
      {/* {(firstName || lastName) && ( */}
      {(!imageURL || fetchError) && (firstName || lastName) && (
        <NameBadge
          imgClassName={imgClassName}
          parentClass={NameBadgeParentClass}
          FirstName={firstName ?? ''}
          LastName={lastName ?? ''}
        />
      )}
      {isLoading.current &&
        !disableLoader &&
        !isImageLoaded &&
        imageURL &&
        showImageLoader && (
          // <Loaders className={loaderClassName} type={loaderType || 'Spin'} />
          // height={height} width={width}
          <div
            className={`imgClassName ${height || 'h-full'} ${width || 'w-full'} `}
          />
        )}
      {/* <Loaders className={loaderClassName} type={loaderType || 'Spin'} /> */}
      {imgComponent()}
      {/* {!firstName && imgComponent()} */}
    </>
  );
};

export default Image;
