export enum DBStores {
  FILES = 'files',
}

export type StoreKeys = {
  [K in DBStores]: string;
};

export const DBStoresKEY: StoreKeys = {
  [DBStores.FILES]: 'path',
};

export interface IData {
  path: string;
  url: string;
  expiresIn: number;
}

const DB_NAME = 'aslDb';
const DB_VERSION = 1;

export const getDBInstance = (): Promise<IDBDatabase | null> => {
  return new Promise((resolve, reject) => {
    try {
      const request = indexedDB.open(DB_NAME, DB_VERSION);

      request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
        const db = (event.target as IDBOpenDBRequest).result;
        Object.entries(DBStoresKEY).forEach(([storeName, keyPath]) => {
          if (!db.objectStoreNames.contains(storeName)) {
            db.createObjectStore(storeName, { keyPath });
          }
        });
      };

      request.onsuccess = () => resolve(request.result);
      request.onerror = () => reject(new Error('Failed to open database'));
    } catch (error) {
      resolve(null);
    }
  });
};

export const clearIndexDBStorage = (): void => {
  indexedDB.deleteDatabase(DB_NAME);
};

export const addData = async <T extends IData>(
  storeName: DBStores,
  data: T
): Promise<boolean> => {
  const db = await getDBInstance();
  const isValidStore = DBStoresKEY[storeName];

  if (!db || !isValidStore) return false;

  return new Promise((resolve) => {
    const transaction = db.transaction(storeName, 'readwrite');
    const store = transaction.objectStore(storeName);
    const request = store.add(data);

    request.onsuccess = () => resolve(true);
    request.onerror = () => resolve(false);
    transaction.oncomplete = () => db.close();
    transaction.onerror = () => resolve(false);
  });
};

export const getData = async (
  storeName: DBStores,
  key: string
): Promise<IData | null> => {
  const db = await getDBInstance();
  const isValidStore = DBStoresKEY[storeName];

  if (!db || !isValidStore) return null;

  return new Promise((resolve) => {
    const transaction = db.transaction(storeName, 'readonly');
    const store = transaction.objectStore(storeName);
    const request = store.get(key);

    request.onsuccess = () => resolve(request.result || null);
    request.onerror = () => resolve(null);
    transaction.oncomplete = () => db.close();
  });
};

export const removeData = async (
  storeName: DBStores,
  key: string
): Promise<boolean> => {
  const db = await getDBInstance();
  const isValidStore = DBStoresKEY[storeName];

  if (!db || !isValidStore) return false;

  return new Promise((resolve) => {
    const transaction = db.transaction(storeName, 'readwrite');
    const store = transaction.objectStore(storeName);
    const request = store.delete(key);

    request.onsuccess = () => resolve(true);
    request.onerror = () => resolve(false);
    transaction.oncomplete = () => db.close();
  });
};
