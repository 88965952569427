export const {
  REACT_APP_NODE_ENV,
  REACT_APP_API_URL,
  REACT_APP_IMAGE_URL,
  REACT_APP_CRYPTO_SECRET_KEY,
  REACT_APP_SUPABASE_URL,
  REACT_APP_SUPABASE_ANON_KEY,
  REACT_APP_FRONTEND_URL,
  REACT_APP_BACKEND_URL,
  REACT_APP_INSTAGRAM_ACCOUNT_URL,
  REACT_APP_SELF_ASSESSMENT_URL,
  REACT_APP_DATE_FORMAT,
  REACT_APP_STRIPE_PUBLIC_KEY,
  REACT_APP_API_BASE_URL,
  REACT_APP_INSTAGRAM_URL,
  REACT_APP_FACEBOOK_URL,
  REACT_APP_WHATSAPP_URL,
  REACT_APP_SHOP_URL,
  REACT_APP_SENTRY_URL,
} = process.env;
