import * as Sentry from '@sentry/react';
import App from 'App';
import { setupAxios } from 'base-axios';
import SocketComponent from 'components/Socket/SocketComponent';
import Toast from 'components/Toast';
import { REACT_APP_SENTRY_URL } from 'config';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import './index.css';

import './localization';
import store, { persistor } from './reduxStore/store';

Sentry.init({
  dsn: REACT_APP_SENTRY_URL ?? '',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({ maskAllText: false, blockAllMedia: false }),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', 'asl-shop.taskgrids.com'],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

setupAxios(store);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <SocketComponent />
      <Toast />
      <App />
    </PersistGate>
  </Provider>
);
