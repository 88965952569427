import { IconTypes } from 'components/Icon/types';
import { FormikErrors } from 'formik';
import { UserModalType } from 'hooks/useModal';
import { Dispatch, SetStateAction } from 'react';

export interface SubscriptionPlansProps {
  id: number;
  icon?: IconTypes;
  title: string;
  // onClick?: MouseEventHandler<HTMLElement>;
  description: string;
  dataKey: PaymentTypeEnum;
}

export interface ICourseListProps {
  payment_type: PaymentTypeEnum;
  org_id?: string;
  values: ISubscriptionInitialValueType;
  allSelfPaceAccess: Boolean;
  setValues: (
    values: React.SetStateAction<ISubscriptionInitialValueType>,
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<ISubscriptionInitialValueType>>;
}

export interface subscriptionCoursesAccessProps {
  id: string;
  title: string;
  icon: IconTypes;
}

export interface ICourseCategory {
  label: string;
  value: string;
}

export interface ICourses {
  id: string;
  title: string;
  cover_image: string;
}

export enum PaymentTypeEnum {
  ONETIME = 'Onetime',
  SUBSCRIPTION = 'Subscription',
}

export enum GiftCardTypeEnum {
  SELF_PLACED = 'Self-Placed',
  ZOOM_IN_PERSOM = 'Zoom-In-Person',
}

export enum TeacherRolesEnum {
  TEACHER = 'Teacher',
  EDITOR = 'Editor',
}

export enum DurationUnitEnum {
  YEAR = 'Year',
  MONTH = 'Month',
  LIFETIME = 'Lifetime',
}

export enum CommunityAccessTypeEnum {
  NO = 'No',
  FULL = 'Full',
  ORGANIZATION_DISCUSSION = 'Organization Discussion',
}

export enum DictionaryAccessEnum {
  NO = 'No',
  FULL = 'Full',
  LIMITED = 'Limited',
}

export interface ICourseTypes {
  id: string;
  type: string;
}

export enum SubscriptionStatusEnum {
  PENDING = 'Pending',
  ACTIVE = 'Active',
  CANCELED = 'Canceled',
}

export interface ISubscriptionAttributesType {
  id: number;
  payment_type: PaymentTypeEnum;
  is_active: boolean;
  title: string;
  stripe_id: string;
  amount: number;
  limited_user_subscription: boolean;
  user_limit: number;
  duration: number;
  duration_unit: DurationUnitEnum;
  note: string;
  all_course_access: boolean;
  has_full_platform_access: boolean;
  has_dictionary_access: DictionaryAccessEnum;
  // course_type: CourseTypeEnum;
  community_access_type: CommunityAccessTypeEnum;
  created_at?: Date | string;
  updated_at?: Date | string;
  deleted_at?: Date | string;
}
export interface ISubscriptionInitialValueType {
  payment_type: PaymentTypeEnum;
  title: string;
  amount: number | null;
  limited_user_subscription: boolean;
  user_limit: number | null;
  duration: number | null;
  duration_unit: DurationUnitEnum;
  note: string;
  all_course_access: boolean;
  has_full_platform_access: 'Yes' | 'No';
  has_dictionary_access: DictionaryAccessEnum;
  community_access_type: CommunityAccessTypeEnum;
  selectAllTypeWise?: boolean;
  type_id?: string;
  courseIds?: string[];
}

export interface ISubscriptionResponseData {
  data: Subscription[];
  count: number;
  currentPage: number;
  limit: number;
  lastPage: number;
}

export type CourseListProps = {
  courses: ICourses[];
  selectedCourses: string[];
  handleSelectAll: () => void;
  getCourseApiLoading: boolean;
  handleSelect: (id: string) => void;
  selectedCourseType?: subscriptionCoursesAccessProps;
};

export type Subscription = {
  id: string;
  payment_type: PaymentTypeEnum;
  is_active: boolean;
  title: string;
  stripe_id: string;
  amount: string;
  limited_user_subscription: boolean;
  user_limit: number;
  subscribed_user?: number;
  note: string;
  duration: number;
  duration_unit: DurationUnitEnum;
  has_full_platform_access: boolean;
  all_course_access: boolean;
  has_dictionary_access: boolean;
  has_community_access: boolean;
  has_some_course_access: boolean;
  created_at: Date;
  updated_at: Date;
  deleted_at: null | Date;
  subscriptionCoursesAccess: SubscriptionCoursesAccessType[];
  subscription_entities: SubscriptionEntity[];
  android_subscription_id: string | null;
  ios_subscription_id: string | null;
  organization_id: string | null;
  organization: {
    id: string;
    user_id: string;
    userDetails: {
      id: string;
      first_name: string;
      last_name: string;
    };
  };
};

export type SubscriptionEntity = {
  subscription_status: SubscriptionStatusEnum;
};

export type subscription_entities = {
  subscription_status: 'Pending';
};

export type SubscriptionCoursesAccessType = {
  id: string;
  course_id: string;
  course: CourseList;
};

export interface viewSubscriptionCourseProps {
  courseList: SubscriptionCoursesAccessType[];
  subscriptionId: string;
  organizationId: string | null;
  allSelfPaceAccess: boolean;
  reload: () => void;
}
export interface CourseList {
  id: string;
  cover_image: string | null;
  title: string;
  type_id: string;
  category_id: string;
}

export type ViewSubscriptionCourseListProps = {
  courses: SubscriptionCoursesAccessType[];
  selectedCourseType?: subscriptionCoursesAccessProps;
};

export type ViewSubscriptionModelProps = {
  model: UserModalType;
  id: string;
  isOrganizationView?: boolean;
};

export type CreateSubscriptionComponentProps = {
  org_id?: string;
  onSuccess?: () => void;
};

export type CreateSubscriptionModelProps = CreateSubscriptionComponentProps & {
  model: UserModalType;
};

export type ViewSubscriptionComponentProps = {
  id: string;
  hideEnrollButton?: boolean;
  isOrganizationView?: boolean;
};

export type DescriptionGeneratorForSubscriptionType = {
  all_course_access: boolean;
  has_dictionary_access: boolean;
  has_community_access: boolean;
  has_some_course_access: boolean;
};

export interface MobileSubscriptionCreatedIndicatorProps {
  subscriptionId: string;
  ios_subscription_id: string | null;
  android_subscription_id: string | null;
  setData: Dispatch<SetStateAction<Subscription | undefined>>;
}
